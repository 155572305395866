<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import router from "@/router/index.js";
//import image from "@/assets/images/users/user.png";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Urgences trajets",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "urgences  trajets",
          active: true,
        },
      ],
      ordersData: [
        {
          ordertrajet: "cotonou >> Parakou",
          user: "Nom & prénom",
          date: "28 Mar, 2020",
        },
      ],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      id: "",
      form: {
        lastname: "",
        firstname: "",
        gender: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      user: {
        firstname: "",
        lastname: "",
        gender: "",
        adress: "",
        profession: "",
        birthday: "",
        phoneNumber: "",
        email: "",
        password: "",
        about: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      UrgencesData: [],
      UrgencesDataDes:[],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "itinéraire", label: "Trajet" },
        { key: "sender", label: "Personne en danger" },
        { key: "conducteur",label: "Conducteur" },
        { key: "date", sortable: true, label: "Date" },
        { key: "action", label: "Voir position" },
      ],
      fieldsAlertesDes: [
        { key: "itinéraire", label: "Trajet" },
        { key: "user", label: "Utilisateur" },
        { key: "date", sortable: true, label: "Date" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },
  validations: {
    form: {
      lastname: { required },
      firstname: { required },
      gender: { required },
      birthday: { required },
      profession: { required },
    },
    user: {
      firstname: { required },
      lastname: { required },
      gender: { required },
      phoneNumber: { required },
      email: { required, email },
      password: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init(){
   const urgenceTrajets = await apiRequest(
      "GET",
      "urgence",
      undefined,
      false
    );

    if (urgenceTrajets && urgenceTrajets.data) {
      console.log("urgences:", urgenceTrajets.data);
      const alerteUrgenceTable = urgenceTrajets.data.map((urgences) => {
        return {
          id: urgences.trajet.id,
          itinéraire: urgences.trajet.lieuDepart+ " " + ">>" + " " + urgences.trajet.lieuArrivee,
          latitudeSender:urgences.latitudeSender,
          longitudeSender:urgences.longitudeSender,
          isVisible:urgences.trajet.isVisible,
          date:new Date(urgences.dateHeure).toLocaleString("fr-FR"),
          conducteur: urgences.conducteur.personne.firstname+" "+urgences.conducteur.personne.lastname,
          sender:urgences.sender.personne.firstname+" "+urgences.sender.personne.lastname,
        };
      });

      this.UrgencesData = alerteUrgenceTable;
      this.id = alert.id;
    }

    // Service pour afficher la liste des alertes désactivées

  /*  const alerteTrajetsDes = await apiRequest(
      "GET",
      "admin/alertes-inactif",
      undefined,
      false
    );*/

   /* if (alerteTrajetsDes && alerteTrajetsDes.data) {
      console.log("alertes:", alerteTrajetsDes.data);
      const alerteTrajetsTableDes = alerteTrajetsDes.data.map((alert) => {
        return {
          id: alert.id,
          itinéraire: alert.lieuDepart + " " + ">>" + " " + alert.lieuArrivee,
          user: alert.alerteur.firstname + " " + alert.alerteur.lastname,       
          date:  new Date(alert.dateAlerte).toLocaleString("fr-FR"),
        };
      });
      this.alerteDataDes = alerteTrajetsTableDes;
    }*/
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    goToDetail(row) {
      console.log("row:", row);
      //router.push(`/trajets/alertes/${row.id}`)
      //router.push('/user/user_info/'+row.id)
    },
    async googleMap(row){
         window.open("http://www.google.com/maps/place/"+row.latitudeSender+","+row.longitudeSender, '_blank');
  
    },
    desactivity(row) {
      this.showdisable= true;
      this.rowCall=row.id
     console.log("row: ",row.id)
    },

    async desactiveAlerte(){
      this.showdisable= false;
    const returnAddSucess = await apiRequest(
      "POST",
      "admin/alerte-active",
      {
        id: this.rowCall,
        active: false,
      },
      false
    );
      if (returnAddSucess && returnAddSucess.data) {
        console.log(returnAddSucess);
        this.init();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Urgences trajets</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des urgences</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="UrgencesData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click="googleMap(row.item)"
                          title="Voir position"
                        >
                          <i class="fas fa-map-marker-alt font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" 
      centered 
      v-model="showdisable" 
      title="Voulez-vous vraiment suspendre cet utilisateur ?" 
      title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable=false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveAlerte">Oui</b-button>
      </div>
      
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
